/*Navbar CSS*/
.Navbar {
  box-shadow: 0px 20px 6px rgba(0, 0, 0, 0.1);
  height: 90px;
  z-index: 999;
}
.head-Navbar {
  margin-top: 8px;
}
.button {
  color: #45E6BF;
  border: 3px solid #45E6BF;
  white-space: nowrap;
  transition: background-color 0.3s, color 1s;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 26px;
}

.button:hover {
  background-color: #45E6BF !important;
  color: #fff;
}

.logo {
  width: 173px;
  margin-top: 10px;
  margin-left: 160px;
}

.logo-switch {
  width: 119px;
  height: 52px;
  margin-top: 12px;
  margin-right: 141px;
}

@media (max-width: 992px) {
  .button {
    font-size: large;
    margin-top: 8px;
    margin-right: 0;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .logo {
    display: none;
  }

  .logo-switch {
    display: none;
  }
}
