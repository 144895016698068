.backgroundI {
  background-image: url("../../Images/telephone.png");
  min-height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.swiper {
  width: 50px;
  height: auto;
}
.carrousel {
  width: 80%;
  margin-top: 6%;
  height: 300px;
}
.overlay_img {
  width: 50px;
  height: auto;
}
.containerCarousel div div  {
 height: 80px;
}
.containerCarousel div img  {
 height: 60px;
 width: auto;
}

