.backgroundImage {
  background-image: url("../../Images/fille_tel.png");
  min-height: 100vh; 
  background-repeat: no-repeat;
  background-size: cover;
}
.container{
    margin-top: 17%;
    width: 50%;
}
.titre{
    color: #45E6BF;
    font-size: 64px;
}
.buttonContent {
    border-color: #5c317a !important;
    white-space: nowrap;
    transition: background-color 0.3s;
    cursor: pointer;
    margin-right: 24px;
    width: 400px;
    text-align: center;
  }
  .buttonContent:hover {
    background-color: #2ae6b8 !important;
    color: #5c317a !important;
  }
  
  .button-contacte {
    border-color:#5c317a !important;
    white-space: nowrap;
    transition: background-color 0.3s;
    cursor: pointer;
    margin-right: 24px;
    width: 400px;
  }
  .button-contacte:hover {
    background-color: #2ae6b8 !important;
    color: #5c317a !important;
  }

.icone_btn{
    height: 50px;
}

@media (max-width: 1100px){
    .container{
        margin-top: 30%;
        width: auto;
        align-items: center;
    }
    
}


@media (max-width: 1400px) {
    .boutonD {
      flex-direction: column;
      text-align: center;
    }
    .boutonD a:last-child button {
      margin-top: 10px; /* Ajustez la valeur selon vos besoins */
    }
  }
