.font {
  margin: 6%;
  background-color: #17e0af;
}
#souscrire {
  width: 300px;
}
#souscrire {
  color: #45e6bf;
  border: 3px solid #45e6bf;
  white-space: nowrap;
  transition: background-color 0.3s, color 1s;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 26px;
}
#souscrire:hover {
  background-color: #45e6bf !important;
  color: #fff;
}
